import React, { useState, useEffect, useRef } from "react";
import searchIcon from "../../Assets/Search1.svg";
import { useMain } from "../../hooks/useMain";
import axios from "axios";
import "./Wishlist.css";
import { toast } from "react-toastify";

const Wishlist = () => {
  const {
    getWishlistbyuser,
    addWishlist,
    removeWishlist,
    addItemtoWishlist,
    removeItemtoWishlist,
    getwishlistItem,
    setcurName,
    setIsorderCall,
    setcurPrice,
    setChartCall,
  } = useMain();

  const [wishlists, setWishlists] = useState([]);
  const [activeWishlist, setActiveWishlist] = useState(null);
  const [wishlistItems, setWishlistItems] = useState([]);
  const [newWishlistName, setNewWishlistName] = useState("");
  const [searchItemName, setSearchItemName] = useState("");
  const [coinList, setCoinList] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [prices, setPrices] = useState({});
  const ws = useRef(null);

  useEffect(() => {
    fetchWishlists();
    fetchCoinList();
  }, []);

  useEffect(() => {
    if (activeWishlist) {
      startWebSocket();
      return () => {
        ws.current.close();
      };
    }
  }, [activeWishlist, wishlistItems]);

  const fetchWishlists = async () => {
    const data = await getWishlistbyuser();
    if (data) {
      setActiveWishlist(data?.data[0]);
      setWishlists(data?.data);
      handleFetchWishlistItems2(data?.data[0]?._id);
    }
  };
  const handleFetchWishlistItems2 = async (wishlistId) => {
    const data = await getwishlistItem(wishlistId);
    setWishlistItems(data?.data);
  };

  // console.log(activeWishlist);
  const fetchCoinList = async () => {
    try {
      const response = await axios.get(
        "https://api.binance.com/api/v3/exchangeInfo"
      );
      const symbols = response?.data?.symbols;
      // console.log(symbols);
      // const coins = symbols
      //   .filter((symbol) => symbol.quoteAsset === "USDT")
      //   .map((symbol) => ({
      //     symbol: symbol.symbol.replace("USDT", ""),
      //     fullName: symbol.baseAsset,
      //   }));
      const coins = symbols.map((symbol) => ({
        symbol: symbol.symbol,
        baseAsset: symbol.baseAsset,
        quoteAsset: symbol.quoteAsset,
      }));
      setCoinList(coins);
    } catch (error) {
      console.error("Error fetching coin list: ", error);
    }
  };

  const startWebSocket = () => {
    ws.current = new WebSocket("wss://stream.binance.com:9443/ws");

    ws.current.onopen = () => {
      const streams = wishlistItems
        .map((item) => `${item.name.toLowerCase()}@ticker`)
        // .map((item) => `${item.name.toLowerCase()}usdt@ticker`)
        .join("/");
      ws.current.send(
        JSON.stringify({
          method: "SUBSCRIBE",
          params: streams.split("/"),
          id: 1,
        })
      );
    };

    ws.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.s && data.c) {
        setPrices((prevPrices) => ({
          ...prevPrices,
          // [data.s.replace("USDT", "")]: data.c,
          [data.s]: data.c,
        }));
      }
    };

    ws.current.onclose = () => {
      console.log("WebSocket connection closed");
    };
  };

  const handleAddWishlist = async () => {
    const data = await addWishlist({ name: newWishlistName });
    setWishlists([...wishlists, data?.data]);
    setNewWishlistName("");
  };

  const handleRemoveWishlist = async (wishlistId) => {
    await removeWishlist(wishlistId);
    setWishlists(wishlists.filter((wishlist) => wishlist._id !== wishlistId));
    if (activeWishlist?._id === wishlistId) {
      setActiveWishlist(null);
      setWishlistItems([]);
      setPrices({});
    }
  };

  const handleFetchWishlistItems = async (wishlistId) => {
    const data = await getwishlistItem(wishlistId);
    setWishlistItems(data?.data);
    setActiveWishlist(
      wishlists.find((wishlist) => wishlist._id === wishlistId)
    );
  };

  const handleAddItemToWishlist = async (symbol) => {
    const itemExists = wishlistItems.some((item) => item.name === symbol);
    if (itemExists) {
      toast.error("Already Exists.");
      return;
    }
    const data = await addItemtoWishlist({
      wishlistId: activeWishlist._id,
      name: symbol,
    });
    console.log(data);
    if(data){
      setWishlistItems([...wishlistItems, data?.data]);
    }
    setSearchItemName("");
    setSearchResults([]);
  };

  const handleRemoveItemFromWishlist = async (itemId) => {
    await removeItemtoWishlist({ wishlistId: activeWishlist._id, itemId });
    setWishlistItems(wishlistItems.filter((item) => item._id !== itemId));
    setPrices((prevPrices) => {
      const updatedPrices = { ...prevPrices };
      delete updatedPrices[itemId];
      return updatedPrices;
    });
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchItemName(value);
    if (value) {
      // const results = coinList.filter((coin) =>
      //   coin.fullName.toLowerCase().includes(value.toLowerCase())
      // );
      const results = coinList.filter(
        (coin) =>
          coin.baseAsset.toLowerCase().includes(value.toLowerCase()) ||
          coin.quoteAsset.toLowerCase().includes(value.toLowerCase()) ||
          coin.symbol.toLowerCase().includes(value.toLowerCase())
      );
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  };

  return (
    <div className="cat-div">
      <div className="wishlist-add">
        {/* <input
          type="text"
          placeholder="Add new wishlist"
          value={newWishlistName}
          onChange={(e) => setNewWishlistName(e.target.value)}
        />
        <button onClick={handleAddWishlist}>Add Wishlist</button> */}
      </div>

      <div className="cat-search">
        <input
          type="text"
          placeholder="Search"
          value={searchItemName}
          onChange={handleSearchChange}
          className="tread-wishlist"
        />
        <img
          src={searchIcon}
          alt="Search"
          onClick={() => {}}
          style={{ cursor: "pointer" }}
        />
      </div>
      {searchResults.length > 0 && (
        <div className="search-results">
          {searchResults.map((coin, index) => (
            <div
              key={index}
              className="search-result-item"
              // onClick={() => handleAddItemToWishlist(coin.symbol)}
              onClick={() =>
                handleAddItemToWishlist(`${coin.baseAsset}${coin.quoteAsset}`)
              }
            >
              {/* {coin.fullName} ({coin.symbol}) */}
              {coin.baseAsset}/{coin.quoteAsset} ({coin.symbol})
            </div>
          ))}
        </div>
      )}
      <div className="cat-heading">
        <h6>Coin</h6>
        <h6>Price</h6>
        <h6>Chart</h6>
        <h6>Trade</h6>
        <h6>Action</h6>
      </div>

      <div className="wishlist-items" style={{ width: "100%" }}>
        {wishlistItems &&
          wishlistItems.length > 0 &&
          wishlistItems.map((item) => (
            <div
              key={item._id}
              className="cat-name"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h6>{item?.name}</h6>
              <h6>
                {Number(prices[item?.name] || 0).toFixed(2) || "Loading..."}
              </h6>
              <h6>
                <button
                  onClick={() => {
                    setChartCall(item.name);
                  }}
                  className="chart-btn"
                >
                  Chart
                </button>
              </h6>
              <h6>
                <button
                  onClick={() => {
                    // console.log(item);
                    setcurName(item.name);
                    setIsorderCall(true);
                    setcurPrice(Number(prices[item?.name] || 0).toFixed(2));
                  }}
                  className="Trade-btn"
                >
                  Trade
                </button>
              </h6>
              <h6>
                <button
                  onClick={() => handleRemoveItemFromWishlist(item._id)}
                  className="Remove-btn"
                >
                  <i class="bi bi-trash-fill"></i>
                </button>
              </h6>
            </div>
          ))}
      </div>
      <br />
      <div className="wishlists_nav_menu">
        {wishlists &&
          wishlists.length > 0 &&
          wishlists.map((wishlist) => (
            <div
              key={wishlist._id}
              className={`wishlist-item ${
                activeWishlist?._id === wishlist._id ? "active" : ""
              }`}
              onClick={() => handleFetchWishlistItems(wishlist._id)}
            >
              <span className="wishlist-name">{wishlist.name}</span> &nbsp;
              {/* <button onClick={() => handleRemoveWishlist(wishlist.id)}>
                Delete
              </button> */}
            </div>
          ))}
      </div>
    </div>
  );
};

export default Wishlist;
