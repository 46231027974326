import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import profile from '../Assets/picture.png'
import msg from '../Assets/material-symbols_chat.png'
import { Link } from 'react-router-dom'

function Community() {
  return (
   <>
    <Navbar/>
    <br/>
    <div className='community-div'>
    <h1>Community</h1>
    <p>The Cryptop Token is the key to unlocking the full potential of Cryptop. Get even<br/> higher earning rates and our lowest borrowing rates.</p>

    </div>

    <br/>
    <div className='community-page'>
    <div className='community-page-section'>
    <Link className='community-page-box nonelist' style={{textDecoration:'none'}} to='/discussion'>

  
    <h2>EURNZD - Seize profitable Opportunity with Anti-shark Pattern?</h2>
    <br/>
    <div className='community-page-box-mheading'>
 <div className='community-page-box-mheading-s'>
 <img src={profile}/>
        <div>
            <h2>By trading_jupiter</h2>
            <p>Android Developer</p>
        </div>
 </div>
        <h3>10 April</h3>
    </div>
    <br/>

    <h4>Halo teman-teman semuanya.
Saya punya pertanyaan, tentang cara untuk menginstall NextJS versi 13, yang dimana NextJS 13 adalah versi terbaru. Saya mempunyai masalah dengan instalasi yang terbaru, saya menda.....</h4>

<h6><img src={msg}/>10  Comments</h6>

  
    </Link>

    <div className='community-page-box'>
    <h2>EURNZD - Seize profitable Opportunity with Anti-shark Pattern?</h2>
    <br/>
    <div className='community-page-box-mheading'>
 <div className='community-page-box-mheading-s'>
 <img src={profile}/>
        <div>
            <h2>By trading_jupiter</h2>
            <p>Android Developer</p>
        </div>
 </div>
        <h3>10 April</h3>
    </div>
    <br/>

    <h4>Halo teman-teman semuanya.
Saya punya pertanyaan, tentang cara untuk menginstall NextJS versi 13, yang dimana NextJS 13 adalah versi terbaru. Saya mempunyai masalah dengan instalasi yang terbaru, saya menda.....</h4>

<h6><img src={msg}/>10  Comments</h6>

    </div>

</div>
    </div>
    <br/>

    <Footer/>
   </>
  )
}

export default Community