import React, { useState } from "react";
import MainContext from "./MainContext";
import { loginuser, sendOtp, createuser, verifyOtp, verifySignup, verifylogin } from "./contextPages/authContext";
import { getAirDropData, getFundData } from "./contextPages/fundContext";
import { getUser, googleLogin, logout, updateCoverPic, updateEmailOtpsend, updateEmailverify, updatePhoneOtpsend, updatePhoneverify, updateProfilePic, updateUserData } from "./contextPages/userContext";
import { addItemtoWishlist, addWishlist, getWishlistbyuser, getwishlistItem, removeItemtoWishlist, removeWishlist } from "./contextPages/wishlistContext";
import { getAllOrder, getAllPosition, getSingleOrder, placeOrder, squareOffOrder, updateOrder } from "./contextPages/paperOrderContext";
import { getAirDrop, getCoinData } from "./contextPages/dataContext";
import { getoptionsymbol_binance } from "./contextPages/binanceContext";
import { getAllOptionOrder, getAllOptionPosition, getSingleOptionOrder, placeOptionOrder, squareOffOptionOrder, updateOptionOrder } from "./contextPages/optionOrderContext";
import { deleteScan, generateScanner, getAllScan, getSingleScan, saveScanner, updateScan } from "./contextPages/scannerContex";
import { addDashBoard, deleteDashBoard, getAllDashBoard, getDashBoardByName, getSingleDashBoard, updateDashBoard } from "./contextPages/dashboardContext";
import { chatSend } from "./contextPages/utilsContext";

const MainState = (props) => {
    const [isLoggin, setIsLoggin] = useState(false)
    const [userData, setUserData] = useState(null)
    const [curName, setcurName] = useState("")
    const [curPrice, setcurPrice] = useState("")
    const [orderCall, setOrderCall] = useState(false)
    const [isorderCall, setIsorderCall] = useState(false)
    const [chartCall, setChartCall] = useState("")
    const [isoptionCall, setIsoptionCall] = useState(false)
    const [optionCall, setoptionCall] = useState(false)
    const [optionData, setoptionData] = useState(false)
    const [spotOrder, setSpotOrder] = useState([])
    const [optionOrder, setOptionOrder] = useState([])
    const handleChangeLoggin = (val) => {
        setIsLoggin(val)
    }
    const handleUser = (val) => {
        setUserData(val)
    }
    console.log(isLoggin);
    return (
        <>
            <MainContext.Provider
                value={{
                    isLoggin,
                    handleChangeLoggin,
                    userData,
                    handleUser,
                    curName, setcurName,
                    curPrice, setcurPrice,
                    isorderCall, setIsorderCall,
                    orderCall, setOrderCall,
                    chartCall, setChartCall,
                    isoptionCall, setIsoptionCall,
                    optionCall, setoptionCall,
                    optionData, setoptionData,
                    spotOrder, setSpotOrder,
                    optionOrder, setOptionOrder,

                    // utils function -----
                    chatSend,

                    createuser,
                    verifySignup,
                    loginuser,
                    verifylogin,
                    sendOtp,
                    verifyOtp,
                    getUser,
                    logout,
                    updateUserData,
                    updateCoverPic,
                    updateProfilePic,
                    googleLogin,
                    updateEmailOtpsend,
                    updateEmailverify,
                    updatePhoneOtpsend,
                    updatePhoneverify,


                    getFundData,
                    getAirDropData,

                    getWishlistbyuser,
                    addWishlist,
                    removeWishlist,
                    addItemtoWishlist,
                    removeItemtoWishlist,
                    getwishlistItem,

                    // paper Trading function
                    placeOrder, updateOrder, getAllOrder, getSingleOrder, getAllPosition, squareOffOrder,

                    getCoinData,
                    getAirDrop,
                    // binance api data
                    getoptionsymbol_binance,


                    // option api funciton
                    placeOptionOrder, updateOptionOrder, getAllOptionOrder, getSingleOptionOrder, getAllOptionPosition, squareOffOptionOrder,

                    //scanner code added
                    generateScanner, saveScanner, updateScan, getAllScan, getSingleScan, deleteScan,

                    // dashboard Context Added
                    addDashBoard, updateDashBoard, deleteDashBoard, getAllDashBoard, getSingleDashBoard, getDashBoardByName
                }}
            >
                {props.children}
            </MainContext.Provider>
        </>
    )
}

export default MainState;