import React, { useEffect, useState } from "react";
import { useMain } from "../../hooks/useMain";
import trash from "../../Assets/delete-filled-svgrepo-com 9.png";
import { useNavigate, useParams } from "react-router-dom";

const ScannerCard = ({ id, scanner, fetchDashboard }) => {
  const navigate = useNavigate();
  const { id: dashboardId } = useParams();
  const [data, setData] = useState([]);
  const { generateScanner, deleteScan } = useMain();
  const [page, setPage] = useState(1);
  const [sortConfig, setSortConfig] = useState({
    key: "name",
    direction: "ascending",
  });
  const itemsPerPage = 10;
  const getData = async () => {
    const { queries } = scanner;
    if (queries.length == 0) {
      return;
    }
    const result = await generateScanner({ queries: queries });
    if (result?.data) {
      setData(result?.data?.filteredResults);
    }
  };
  useEffect(() => {
    getData();
  }, [id]);

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const handleDelete = async (scannerId) => {
    const res = await deleteScan(dashboardId, scannerId);
    fetchDashboard();
  };

  const getSortIcon = (key) => {
    if (sortConfig.key !== key) {
      return "↑↓";
    }
    return sortConfig.direction === "ascending" ? "↑" : "↓";
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const sortedData = [...data].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === "ascending" ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === "ascending" ? 1 : -1;
    }
    return 0;
  });

  const startIndex = (page - 1) * itemsPerPage;
  const displayedData = sortedData.slice(startIndex, startIndex + itemsPerPage);

  return (
    <div className="scanner-card">
      <div style={{ display: "flex", gap: "10px" }}>
        <h3>{scanner.name}</h3>
        <div>
          <img
            src={trash}
            alt="Delete"
            onClick={() => handleDelete(scanner?._id)}
          />{" "}
        </div>
        <div className="Run-scan-div">
          <div className="Run-scan">
            <button
              className="Run-scan-btn Runscan"
              onClick={() => navigate(`/Screener/${scanner?._id}`)}
            >
              Edit
            </button>
          </div>
        </div>
      </div>
      <div className="stock-passesall-div stock-passesall-div2">
        <table className="table">
          <thead className="thead-dark">
            <tr>
              <th scope="col" onClick={() => handleSort("name")}>
                Name {getSortIcon("name")}
              </th>
              <th scope="col">Symbol</th>
              <th scope="col" onClick={() => handleSort("price")}>
                Price {getSortIcon("price")}
              </th>
              <th scope="col" onClick={() => handleSort("volume")}>
                Volume {getSortIcon("volume")}
              </th>
            </tr>
          </thead>
          <tbody>
            {displayedData.map((item) => (
              <tr key={item.symbol}>
                <th scope="row">{item.name}</th>
                <td>{item.symbol}</td>
                <td style={{ color: item.color }}>
                  ${item.currentCandle?.open?.toFixed(2)}
                </td>
                <td>${item.currentCandle?.volume?.toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="pagination">
          <button
            onClick={() => handlePageChange(page - 1)}
            disabled={page === 1}
          >
            Previous
          </button>
          <span>Page {page}</span>
          <button
            onClick={() => handlePageChange(page + 1)}
            disabled={startIndex + itemsPerPage >= data.length}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default ScannerCard;
