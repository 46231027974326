import React, { useState, useEffect, useRef } from "react";
import Navbar from "../Components/Navbar";
import searchicon from "../Assets/Search.svg";
import img1 from "../Assets/add-square-svgrepo-com 1.svg";
import img2 from "../Assets/copy-svgrepo-com 1.svg";
import img3 from "../Assets/Group 34629.png";
import icon from "../Assets/ICon (1).svg";
import icon2 from "../Assets/save-floppy-svgrepo-com 1.png";
import icon3 from "../Assets/Vector (1).png";
import icon4 from "../Assets/monitor-svgrepo-com 1.png";
import icon5 from "../Assets/result-old-svgrepo-com 1.png";
import icon6 from "../Assets/export-3-svgrepo-com 1.png";
import Footer from "../Components/Footer";
import { useNavigate, useParams } from "react-router-dom";
import { useMain } from "../hooks/useMain";
import ConditionQuery from "./scannerinput/ConditionQuery";
import IndicatorQuery from "./scannerinput/IndicatorQuery";
import VolumeQuery from "./scannerinput/VolumeQuery";
import RSIQuery from "./scannerinput/RSIQuery";
import MACDQuery from "./scannerinput/MADCQuery";
import BollingerQuery from "./scannerinput/BollingerQuery";
import ATRQuery from "./scannerinput/ATRQuery";
import ADXQuery from "./scannerinput/ADXQuery";
import { toast } from "react-toastify";
import ScannerModel from "../SubComponent/Scanner/ScannerModel";

function Home() {
  const {id} = useParams()
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [sortConfig, setSortConfig] = useState({
    key: "name",
    direction: "ascending",
  });
  const itemsPerPage = 20;
  const previousPrices = useRef({});
  const { generateScanner, saveScanner, getSingleScan } = useMain();
  // const [queries, setQueries] = useState([{ type: 'condition', interval: '1h', valueType: 'close', operation: '>=', compareInterval: '1h', compareValueType: 'close' }]);
  const [queries, setQueries] = useState([]);
  const [queryValidity, setQueryValidity] = useState([]);
  const [scanToggle, setScantoggle] = useState(false);
  const [scanName, setScanName] = useState("");
  const [singleScan, setSingleScan] = useState({})
  const [showModal, setShowModal] = useState(false);
  const handleSingleScanData = async()=>{
    const res = await getSingleScan(id)
    if(res?.data){
      setSingleScan(res?.data)
      setQueries(res?.data?.queries)
    }
  }
  useEffect(()=>{
    if(id){
      handleSingleScanData()
    }
  },[id])

  const handleQueryChange = (index, field, value) => {
    const newQueries = [...queries];
    newQueries[index][field] = value;
    setQueries(newQueries);

    const newQueryValidity = [...queryValidity];
    newQueryValidity[index] = validateQuery(newQueries[index]);
    setQueryValidity(newQueryValidity);
  };

  const addQuery = () => {
    setQueries([...queries, { operation: "" }]);
    setQueryValidity([...queryValidity, false]);
  };

  const validateQuery = (query) => {
    for (let key in query) {
      if (
        query[key] === "" ||
        query[key] === null ||
        query[key] === undefined
      ) {
        return false;
      }
    }
    return true;
  };

  const removeQuery = (index) => {
    const newQueries = queries.filter((_, i) => i !== index);
    const newQueryValidity = queryValidity.filter((_, i) => i !== index);
    setQueries(newQueries);
    setQueryValidity(newQueryValidity);
  };

  const runScan = async () => {
    if (queryValidity.includes(false)) {
      toast.error("Please fill all the fields correctly.");
      return;
    }
    if (queries.length <= 0) {
      return toast.error("Please Add Atleast One Query.");
    }
    const result = await generateScanner({ queries: queries });
    if (result?.data) {
      setData(result?.data?.filteredResults);
    }
    console.log("Running scan with queries:", result);
  };

  const handlesaveScan = async () => {
    if (queryValidity.includes(false)) {
      toast.error("Please fill all the fields correctly.");
      return;
    }
    if (!scanName) {
      return toast.error("Please Enter Scan Name");
    }
    if (queries.length <= 0) {
      return toast.error("Please Add Atleast One Query.");
    }
    const result = await saveScanner({ queries: queries, name: scanName });
    if (result?.data) {
      toast.success("Scanner Added.");
    }
    console.log("Running scan with queries:", result);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const sortedData = [...data].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === "ascending" ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === "ascending" ? 1 : -1;
    }
    return 0;
  });

  const startIndex = (page - 1) * itemsPerPage;
  const displayedData = sortedData.slice(startIndex, startIndex + itemsPerPage);

  const getSortIcon = (key) => {
    if (sortConfig.key !== key) {
      return "↑↓";
    }
    return sortConfig.direction === "ascending" ? "↑" : "↓";
  };

  const renderQueryComponent = (query, index) => {
    switch (query.scannertype) {
      case "condition":
        return (
          <ConditionQuery
            key={index}
            query={query}
            handleQueryChange={handleQueryChange}
            index={index}
          />
        );
      case "indicator":
        return (
          <IndicatorQuery
            key={index}
            query={query}
            handleQueryChange={handleQueryChange}
            index={index}
          />
        );
      case "volume":
        return (
          <VolumeQuery
            key={index}
            query={query}
            handleQueryChange={handleQueryChange}
            index={index}
          />
        );
      case "rsi":
        return (
          <RSIQuery
            key={index}
            query={query}
            handleQueryChange={handleQueryChange}
            index={index}
          />
        );
      case "bollingerbands":
        return (
          <BollingerQuery
            key={index}
            query={query}
            handleQueryChange={handleQueryChange}
            index={index}
          />
        );
      case "atr":
        return (
          <ATRQuery
            key={index}
            query={query}
            handleQueryChange={handleQueryChange}
            index={index}
          />
        );
      case "macd":
        return (
          <MACDQuery
            key={index}
            query={query}
            handleQueryChange={handleQueryChange}
            index={index}
          />
        );
      case "adx":
        return (
          <ADXQuery
            key={index}
            query={query}
            handleQueryChange={handleQueryChange}
            index={index}
          />
        );

      default:
        return null;
    }
  };

  return (
    <>
      <Navbar />
      <br />
      <div className="center stockscreen">
        <h1>Crypto Screener</h1>
        <p style={{ textAlign: "center" }}>
          The Cryptop Token is the key to unlocking the full potential of
          Cryptop. Get even
          <br />
          higher earning rates and our lowest borrowing rates.
        </p>
        <div className="searchtab">
          <input placeholder="Search" />
          <img src={searchicon} />
        </div>
      </div>

      {/* <div className="stock-passesall-div">
        <div className="stock-passesall">
          <div className="stock-passesall-btn">
            <p>
              Stock passes all of the <br />
              below filters in cash segment:{" "}
            </p>
            <div className="stock-passesall-btn-div">
              <button>Scanner Guide</button>
              <button>Scanner Example</button>
              <button>Feedback</button>
            </div>
          </div>

          <div className="addbox">
            <img src={img1} />
            <img src={img2} />
          </div>
          <br />
          <div className="addbox">
            <button>Select A Measure Root</button>
            <button>Select A Measure Root</button>
            <button>Select A Measure Root</button>
          </div>
          <br />
        </div>
      </div> */}
{/* 
      <div className="stock-passesall-div">
        <button onClick={() => navigate("/dashboard")}>
          List of Save Scan
        </button>
      </div> */}
      <div className="stock-passesall-div">
        <div>
          {queries.map((query, index) => (
            <div
              key={index}
              className="query-box"
              style={{ display: "flex", gap: "10px" }}
            >
              <select
                value={query.scannertype}
                onChange={(e) =>
                  handleQueryChange(index, "scannertype", e.target.value)
                }
                style={{
                  borderColor:
                    query.scannertype === null ||
                    query.scannertype === undefined ||
                    query.scannertype === ""
                      ? "red"
                      : "initial",
                }}
              >
                <option value="">Select</option>
                <option value="condition">Condition</option>
                <option value="indicator">Indicator</option>
                <option value="volume">Volume</option>
                <option value="rsi">RSI</option>
                <option value="bollingerbands">Bollinger Bands</option>
                <option value="atr">ATR</option>
                <option value="macd">MACD</option>
                <option value="adx">ADX</option>
                {/* Add other options as needed */}
              </select>
              {renderQueryComponent(query, index)}
              <button onClick={() => removeQuery(index)}>Remove Query</button>
            </div>
          ))}
          <button onClick={addQuery}>Add Query</button>
        </div>
      </div>

      <div className="Run-scan-div">
        <div className="Run-scan">
          <button className="Run-scan-btn Runscan" onClick={() => runScan()}>
            {/* <img src={icon} /> */}
            Run Scan
          </button>
          <button
            className="Run-scan-btn SaveScan"
            onClick={() => setShowModal(true)}
          >
            {/* <img src={icon2} /> */}
            Save Scan
          </button>
          <button className="Run-scan-btn CreateAlert">
            {/* <img src={icon3} /> */}
            Create Alert
          </button>
          <button className="Run-scan-btn Monitor" onClick={()=>navigate("/dashboard")}>
            {/* <img src={icon4} /> */}
            Monitor & Dashboard
          </button>
          <button className="Run-scan-btn Backtest">
            {/* <img src={icon5} /> */}
            Backtest Results
          </button>
          <button className="Run-scan-btn Export">
            {/* <img src={icon6} /> */}
            Export
          </button>
        </div>
      </div>
      {/* {scanToggle && (
        <div className="Run-scan-div">
          <div className="Run-scan">
            <input
              type="text"
              placeholder="Scan Name"
              value={scanName}
              onChange={(e) => setScanName(e.target.value)}
            />
            <button
              className="Run-scan-btn Runscan"
              onClick={() => handlesaveScan()}
            >
              <img src={icon} />
              Add Scan
            </button>
          </div>
        </div>
      )} */}

      <div className="stock-passesall-div">
        <div className="stock-passesall">
          <div className="stock-passesall-row">
            <div className="checkbox-div">
              <input type="checkbox" />
              <p>Open New Charts</p>
            </div>
            <div className="checkbox-div">
              <input type="checkbox" />
              <p>Open New Charts</p>
            </div>
          </div>
        </div>
      </div>

      <br />
      <div className="stock-passesall-div stock-passesall-div2">
        <table className="table">
          <thead className="thead-dark">
            <tr>
              <th scope="col" onClick={() => handleSort("name")}>
                Name {getSortIcon("name")}
              </th>
              <th scope="col">Symbol</th>
              <th scope="col" onClick={() => handleSort("price")}>
                Price {getSortIcon("price")}
              </th>
              <th scope="col" onClick={() => handleSort("volume")}>
                Volume {getSortIcon("volume")}
              </th>
            </tr>
          </thead>
          <tbody>
            {displayedData.map((item) => (
              <tr key={item.symbol}>
                <th scope="row">{item.name}</th>
                <td>{item.symbol}</td>
                <td style={{ color: item.color }}>
                  ${item.currentCandle?.open?.toFixed(2)}
                </td>
                <td>${item.currentCandle?.volume?.toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="pagination">
          <button
            onClick={() => handlePageChange(page - 1)}
            disabled={page === 1}
          >
            Previous
          </button>
          <span>Page {page}</span>
          <button
            onClick={() => handlePageChange(page + 1)}
            disabled={startIndex + itemsPerPage >= data.length}
          >
            Next
          </button>
        </div>
      </div>
      <ScannerModel 
       show={showModal}
       handleClose={() => setShowModal(false)}
       queryValidity ={queryValidity}
       queries={queries}
      />


      <Footer />
    </>
  );
}

export default Home;
