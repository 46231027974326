import axios from "axios";
import { headers, jwtHeaders } from "../common/data";
import { scannerBaseUrl as baseUrl } from "../../utils/binanceurl";


export const addDashBoard = async (data) => {
    try {
        const res = await axios.post(`${baseUrl}/dashboard`, data, { headers: jwtHeaders() })
        if (res?.status == 200) {
            return res.data
        }
    } catch (error) {
        console.log(error.message);
    }
}

export const deleteDashBoard = async (id) => {
    try {
        const res = await axios.delete(`${baseUrl}/dashboard/${id}`, { headers: jwtHeaders() })
        if (res?.status == 200) {
            return res.data
        }
    } catch (error) {
        console.log(error.message);
    }
}

export const getAllDashBoard = async () => {
    try {

        const res = await axios.get(`${baseUrl}/dashboard`, { headers: jwtHeaders() })
        if (res?.status == 200) {
            return res.data
        }
    } catch (error) {
        console.log(error.message);
    }
}

export const getSingleDashBoard = async (id) => {
    try {
        const res = await axios.get(`${baseUrl}/dashboard/${id}`, { headers: jwtHeaders() })
        if (res?.status == 200) {
            return res.data
        }
    } catch (error) {
        console.log(error.message);
    }
}

export const updateDashBoard = async (data, id) => {
    try {
        const res = await axios.put(`${baseUrl}/dashboard/${id}`, data, { headers: jwtHeaders() })
        if (res?.status == 200) {
            return res.data
        }
    } catch (error) {
        console.log(error.message);
    }
}

export const getDashBoardByName = async (name) => {
    try {
        const res = await axios.get(`${baseUrl}/dashboard/byname?name=${name}`, { headers: jwtHeaders() })
        if (res?.status == 200) {
            return res.data
        }
    } catch (error) {
        console.log(error.message);
    }
}
