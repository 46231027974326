import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useMain } from "../../hooks/useMain";
import { toast } from "react-toastify";


const ScannerModel = ({ show, handleClose, queryValidity, queries }) => {
  const [allDashboard, setAllDashboard] = useState([]);
  const [scanName, setScanName] = useState("");
  const [dashboardId, setDashboardId] = useState("");
  const [showAddDashboard, setShowAddDashboard] = useState(false);
  const [newDashboardName, setNewDashboardName] = useState("");

  const { getAllDashBoard, addDashBoard, saveScanner } = useMain();

  const getData = async () => {
    const res = await getAllDashBoard();
    setAllDashboard(res?.data || []);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleAddDashboard = async () => {
    if (!newDashboardName) {
      return toast.error("Please enter a dashboard name.");
    }
    const res = await addDashBoard({ name: newDashboardName });
    if (res?.data) {
      toast.success("Dashboard added.");
      getData(); // Refresh dashboard list
      setShowAddDashboard(false);
      setNewDashboardName("");
    }
  };

  const handleSaveScanner = async () => {
    if (queries.length <= 0) {
      return toast.error("Please add at least one query.");
    }
    if (queryValidity.includes(false)) {
      toast.error("Please fill all the fields correctly.");
      return;
    }
    if (!scanName) {
      return toast.error("Please enter a scan name.");
    }
    const result = await saveScanner({ queries, name: scanName, dashboardId });
    if (result?.data) {
      toast.success("Scanner added.");
      handleClose();
    }
    console.log("Running scan with queries:", result);
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Manage Scanner</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="selectDashboard">
            <Form.Label>Select Dashboard</Form.Label>
            <Form.Control
              as="select"
              value={dashboardId}
              onChange={(e) => setDashboardId(e.target.value)}
            >
              <option value="">Select a dashboard</option>
              {allDashboard.map((dashboard) => (
                <option key={dashboard._id} value={dashboard._id}>
                  {dashboard.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Button
            variant="link"
            onClick={() => setShowAddDashboard(!showAddDashboard)}
          >
            {showAddDashboard ? "Cancel" : "Add Dashboard"}
          </Button>
          {showAddDashboard && (
            <>
              <Form.Group controlId="newDashboardName">
                <Form.Label>New Dashboard Name</Form.Label>
                <Form.Control
                  type="text"
                  value={newDashboardName}
                  onChange={(e) => setNewDashboardName(e.target.value)}
                />
              </Form.Group>
              <Button variant="primary" onClick={handleAddDashboard}>
                Save Dashboard
              </Button>
            </>
          )}
          <Form.Group controlId="scanName">
            <Form.Label>Scan Name</Form.Label>
            <Form.Control
              type="text"
              value={scanName}
              onChange={(e) => setScanName(e.target.value)}
              placeholder="Enter scan name"
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Back
        </Button>
        <Button variant="primary" onClick={handleSaveScanner}>
          Save Scanner
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ScannerModel;
