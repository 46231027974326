import React, { useState } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";

import p1 from "../Assets/image 113.png";
import axios from "axios";
import { useMain } from "../hooks/useMain";
import BasicInfo from "./Profile/BasicInfo";
import ProfileHeader from "./Profile/ProfileHeader";

function Profile() {
  const [activeTab, setActiveTab] = useState(1);
  
  const { userData } = useMain()
  console.log(userData);

  const handleTabChange = (tabIndex) => {
    setActiveTab(tabIndex);
  };
  return (
    <>
      <Navbar />
      <ProfileHeader />
      <br />
      <br />
      <br />
      <br />
      <br />

      <div className="tab-container-main">
        <div className="tab-container">
          <div className="tabs">
            <button
              className={activeTab === 1 ? "tab-btn active" : "tab-btn"}
              onClick={() => handleTabChange(1)}
            >
              Profile
            </button>
            <button
              className={activeTab === 2 ? "tab-btn active" : "tab-btn"}
              onClick={() => handleTabChange(2)}
            >
              Privacy & Security
            </button>
            <button
              className={activeTab === 3 ? "tab-btn active" : "tab-btn"}
              onClick={() => handleTabChange(3)}
            >
              KYC
            </button>
            <button
              className={activeTab === 4 ? "tab-btn active" : "tab-btn"}
              onClick={() => handleTabChange(4)}
            >
              Third Party Integiration
            </button>
          </div>
          <br />
          <div className="tab-content">
            {activeTab === 1 &&  <BasicInfo />}
            {activeTab === 2 && <p>tab2</p>}
            {activeTab === 3 && (
              <p>
                <p>
                  <div className="tab-content-div">
                    <div className="tab-content-innerdiv">
                      <div className="tab-content-heading">
                        <h3>Kyc Verification</h3>
                        <p>
                          This information will be publicly displayed and
                          visible for all users.
                        </p>
                      </div>
                      <div className="tab-content-inputs">
                        <h4>
                          1. Proof of identification,which must include any one
                          of following
                        </h4>
                        <br />
                        <h4>International Passport</h4>
                        <br />

                        <h4>Government provided ID (with name and Address) </h4>
                        <br />

                        <h4>Driving License </h4>

                        <br />
                        <div className="tab-barinput">
                          <input placeholder="Select Id Proof One Side" />
                          <button>Upload</button>
                        </div>
                        <br />
                        <div className="tab-barinput">
                          <input placeholder="Select Id Proof One Side" />
                          <button>Upload</button>
                        </div>
                        <br />
                        <h4>
                          2. Selfie picture trader holding the ID use to
                          Register on the platform notice
                        </h4>

                        <br />
                        <div className="tab-barinput">
                          <input placeholder="Select Id Proof One Side" />
                          <button>Upload</button>
                        </div>
                        <br />
                        <button className="tabsubmit">Submit</button>
                      </div>
                    </div>
                  </div>
                </p>
              </p>
            )}
            {activeTab === 4 && (
              <p>
                <div className="CryptoBrokers">
                  <h1>Crypto Brokers</h1>
                  <br />
                  <div className="Cryptocards">
                    <img src={p1} />
                    <br />
                    <h2>Alpaca Trading</h2>
                    <p>Crypto Score: 4.8/5</p>
                    <br />

                    <div className="Cryptocards-boxs">
                      <div className="Cryptocards-box">
                        <h3>16k</h3>
                        <h4>Reviews</h4>
                      </div>
                      <div className="Cryptocards-box">
                        <h3>16k</h3>
                        <h4>Reviews</h4>
                      </div>
                    </div>
                    <br />

                    <div className="Cryptocards-boxs-btn">
                      <button className="linkacc">Link Account</button>
                      <button className="unlink">Un Link Account</button>
                    </div>
                  </div>
                </div>
              </p>
            )}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Profile;
