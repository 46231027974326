import React from "react";
import logo from "../Assets/newlogo.png";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <div className="footer">
        <ul>
          <img className="foot-logo" src={logo} />
        </ul>

        <ul>
          Links
          <div className="line"></div>
          <Link to="/" className="nonek">
            <li>Home</li>
          </Link>
          <Link to="/Screener" className="nonek">
            <li>Screeners </li>
          </Link>
          <Link to="/Screener" className="nonek">
            <li>Trading</li>
          </Link>
          {/* <Link  to='/Screener' className='nonek'>
        
        <li>Contact us</li>
        </Link> */}
        </ul>
        <ul>
          Links
          <div className="line"></div>
          <Link to="/Funds" className="nonek">
            <li>Funds</li>
          </Link>
          <Link to="/community" className="nonek">
            <li>Community </li>
          </Link>
          <Link to="/community" className="nonek">
            <li>More</li>
          </Link>
        </ul>
        <ul>
          Legal
          <div className="line"></div>
          <Link to="/policy" className="nonek">
            <li>Privacy Policy</li>
          </Link>
          <Link to="/Terms" className="nonek">
            <li>Terms and Conditions </li>
          </Link>
        </ul>
        <ul>
          Our Information
          <div className="line"></div>
          <Link className="nonek">
            <li>Contact Us</li>
          </Link>
        </ul>
      </div>
      <div className="footer-bottom">
        <p>Copyright@COINYOGA</p>
      </div>
    </>
  );
}

export default Footer;
