import React, { useState, useEffect } from "react";
import searchicon from "../../Assets/Search.svg";
import { useNavigate } from "react-router-dom";
import { useMain } from "../../hooks/useMain";

const DashBoardSearch = () => {
  const navigate = useNavigate();
  const { getDashBoardByName } = useMain();
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState([]);
  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };
  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      if (searchTerm) {
        getDashBoardByName(searchTerm).then((res) => {
          if (res.data) {
            setResults(res.data);
          } else {
            setResults([]);
          }
        });
      } else {
        setResults([]); 
      }
    }, 300); 
    return () => clearTimeout(debounceTimeout);
  }, [searchTerm, getDashBoardByName]);

  return (
    <div className="searchtab">
      <div className="input-group">
        <input
          type="text"
          className="form-control"
          placeholder="Search"
          value={searchTerm}
          onChange={handleInputChange}
        />
        <div className="input-group-append">
          <span className="input-group-text">
            <img src={searchicon} alt="search icon" />
          </span>
        </div>
      </div>

      {/* Results List */}
      <ul className="list-group mt-3">
        {results && results?.length>0 && results.map((dashboard) => (
          <li
            key={dashboard._id}
            className="list-group-item list-group-item-action"
            onClick={() => navigate(`/dashboard/${dashboard._id}`)}
          >
            {dashboard.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DashBoardSearch;
