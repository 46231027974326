import React, { useEffect, useState } from "react";

import Navbar from "../Components/Navbar";

import img1 from "../Assets/add-square-svgrepo-com 1.svg";
import img2 from "../Assets/copy-svgrepo-com 1.svg";
import img3 from "../Assets/Group 34629.png";
import icon from "../Assets/ICon (1).svg";
import icon2 from "../Assets/save-floppy-svgrepo-com 1.png";
import icon3 from "../Assets/Vector (1).png";
import icon4 from "../Assets/monitor-svgrepo-com 1.png";
import icon5 from "../Assets/result-old-svgrepo-com 1.png";
import icon6 from "../Assets/export-3-svgrepo-com 1.png";
import Footer from "../Components/Footer";
import ashboard from "../Assets/dashboard-svgrepo-com 2.png";
import profile from "../Assets/Group 34630.png";
import trash from "../Assets/delete-filled-svgrepo-com 9.png";
import { useNavigate } from "react-router-dom";
import { useMain } from "../hooks/useMain";
import AllScanner from "./Dashboard/AllScanner";
import DashBoardSearch from "./Dashboard/DashBoardSearch";

function Dashboard() {
  return (
    <>
      <Navbar />
      <br />
      <br />
      <div className="center stockscreen">
        <h1>Dashboard</h1>
        <br />

        <p style={{ textAlign: "center" }}>
          The Cryptop Token is the key to unlocking the full potential of
          Cryptop. Get even
          <br /> higher earning rates and our lowest borrowing rates.
        </p>
        <br />

        <DashBoardSearch />
      </div>
      <br />
      <br />
      <AllScanner />
      <br />
      <br />
      {/* <div className="creatDashboard">
        <h1>Top Dashbard</h1>
      </div> */}

      {/* <br /> */}
      {/* <div className="yourboard">
        <div className="dashboard-card">
          <img src={profile} />
          <div className="dashboard-card-text">
            <h3>Intraday Traders desk</h3>
            <p>
              Has 11 Widgets <img src={trash} />{" "}
            </p>
          </div>
        </div>
        <div className="dashboard-card">
          <img src={profile} />
          <div className="dashboard-card-text">
            <h3>Intraday Traders desk</h3>
            <p>
              Has 11 Widgets <img src={trash} />{" "}
            </p>
          </div>
        </div>
        <div className="dashboard-card">
          <img src={profile} />
          <div className="dashboard-card-text">
            <h3>Intraday Traders desk</h3>
            <p>
              Has 11 Widgets <img src={trash} />{" "}
            </p>
          </div>
        </div>
        <div className="dashboard-card">
          <img src={profile} />
          <div className="dashboard-card-text">
            <h3>Intraday Traders desk</h3>
            <p>
              Has 11 Widgets <img src={trash} />{" "}
            </p>
          </div>
        </div>
      </div> */}
      <br />
      <br />

      <Footer />
    </>
  );
}

export default Dashboard;
