import React from "react";
import { Card, ListGroup, ListGroupItem } from "react-bootstrap";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";

const UpcomingFeatures = () => {
  return (
    <>
    <Navbar />
    <div className="container mt-5">
      <Card>
        <Card.Header as="h3" className="text-center">
          Upcoming Features
        </Card.Header>
        <ListGroup variant="flush">
          <ListGroupItem>
            <h5>Screener Back-Testing</h5>
            <p>
              Test your strategies against historical data to see how they would have performed. Optimize your approach before applying it in real markets.
            </p>
          </ListGroupItem>
          <ListGroupItem>
            <h5>Community</h5>
            <p>
              Join a vibrant community of traders and investors. Share insights, strategies, and stay connected with like-minded individuals.
            </p>
          </ListGroupItem>
          <ListGroupItem>
            <h5>Alerting and Dashboard Sharing</h5>
            <p>
              Set up custom alerts and share your personalized dashboard with others. Stay informed and collaborate seamlessly.
            </p>
          </ListGroupItem>
          <ListGroupItem>
            <h5>Export Screening</h5>
            <p>
              Export your screening results for further analysis or sharing. Easily transfer data into your preferred format.
            </p>
          </ListGroupItem>
          <ListGroupItem>
            <h5>ICO + Airdrop + Funds Updates</h5>
            <p>
              Get timely updates on upcoming ICOs, airdrops, and fund opportunities. Stay ahead of the curve with the latest information.
            </p>
          </ListGroupItem>
        </ListGroup>
      </Card>
      <br /><br />
      <br /><br />
    </div>
    <Footer />
    </>
  );
};

export default UpcomingFeatures;
