import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import coinImg from "../../../Assets/Bitcoin.svg";

const GainerAndLooser = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [gainers, setGainers] = useState([]);
  const [losers, setLosers] = useState([]);
  // console.log(gainers);
  const ws = useRef(null);

  useEffect(() => {
    const fetchOpeningPrices = async () => {
      try {
        const response = await axios.get(
          "https://api.binance.com/api/v3/ticker/24hr"
        );
        const usdtPairs = response.data.filter((coin) =>
          coin.symbol.endsWith("USDT")
        );
        // console.log(usdtPairs);

        const pairsWithOpeningPrice = await Promise.all(
          usdtPairs.map(async (coin) => {
            const symbol = coin.symbol;
            const startTime = new Date();
            // const openingResponse = await axios.get(`https://api.binance.com/api/v3/klines?symbol=${symbol}&interval=1d&startTime=${startTime}&limit=1`);
            // const openingPrice = parseFloat(openingResponse.data[0][1]);
            return {
              symbol: coin.symbol,
              currentPrice: parseFloat(coin.lastPrice),
              openingPrice: parseFloat(coin.openPrice),
              percentageChange:
                ((parseFloat(coin.lastPrice) - parseFloat(coin.openPrice)) /
                  parseFloat(coin.openPrice)) *
                100,
            };
          })
        );

        const updatedGainers = pairsWithOpeningPrice
          .sort((a, b) => b.percentageChange - a.percentageChange)
          .slice(0, 5);
        const updatedLosers = pairsWithOpeningPrice
          .sort((a, b) => a.percentageChange - b.percentageChange)
          .slice(0, 5);

        setGainers(updatedGainers);
        setLosers(updatedLosers);
      } catch (error) {
        console.error("Error fetching opening prices:", error);
      }
    };

    fetchOpeningPrices();

    ws.current = new WebSocket("wss://stream.binance.com:9443/ws");

    ws.current.onopen = () => {
      const subscribeMessage = {
        method: "SUBSCRIBE",
        params: ["!ticker@arr"],
        id: 1,
      };
      ws.current.send(JSON.stringify(subscribeMessage));
    };

    ws.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (Array.isArray(data)) {
        const usdtPairs = data.filter((coin) => coin.s.endsWith("USDT"));
        // console.log(data);
        const updatedPrices = usdtPairs.map((coin) => {
          const matchingPair =
            gainers.find((g) => g.symbol === coin.s) ||
            losers.find((l) => l.symbol === coin.s);
          const openingPrice = matchingPair
            ? matchingPair.openingPrice
            : parseFloat(coin.o);

          return {
            symbol: coin.s,
            currentPrice: parseFloat(coin.c),
            openingPrice,
            percentageChange:
              ((parseFloat(coin.c) - openingPrice) / openingPrice) * 100,
          };
        });

        const updatedGainers = updatedPrices
          .sort((a, b) => b.percentageChange - a.percentageChange)
          .slice(0, 5);
        const updatedLosers = updatedPrices
          .sort((a, b) => a.percentageChange - b.percentageChange)
          .slice(0, 5);

        setGainers(updatedGainers);
        setLosers(updatedLosers);
      }
    };

    ws.current.onclose = () => {
      console.log("WebSocket closed, attempting to reconnect...");
    };

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, []);

  const handleTabChange = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <div className="home-tabs">
      <div className="home-tab">
        <button
          className={activeTab === 1 ? "tab-btn active" : "tab-btn"}
          onClick={() => handleTabChange(1)}
        >
          Top Gainers
        </button>
        <button
          className={activeTab === 3 ? "tab-btn active" : "tab-btn"}
          onClick={() => handleTabChange(3)}
        >
          Top Losers
        </button>
      </div>
      <div className="tab-content">
        {activeTab === 1 && (
          <div className="coincrypto-cards">
            <div className="coincrypto-cards-div">
              {gainers.map((coin) => (
                <div key={coin.symbol} className="coincrypto-card">
                  {/* <img src={coinImg} alt={coin.symbol} /> */}
                  <div className="cc">
                    <div className="cc-left">
                      <p>
                        {coin.symbol}{" "}
                        <div className="cc-right">
                          <button>{coin.percentageChange.toFixed(2)}%</button>
                        </div>
                        <div>
                          <br />
                          <span>Open : ${coin.openingPrice.toFixed(4)}</span>
                          <br />
                          <span>Price : ${coin.currentPrice.toFixed(4)}</span>
                        </div>
                      </p>
                    </div>
                    {/* <div className="cc-right">
                      <button>{coin.percentageChange.toFixed(2)}%</button>
                    </div> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {activeTab === 3 && (
          <div className="coincrypto-cards">
            <div className="coincrypto-cards-div">
              {losers.map((coin) => (
                <div key={coin.symbol} className="coincrypto-card">
                  {/* <img src={coinImg} alt={coin.symbol} /> */}
                  <div className="cc">
                    <div className="cc-left">
                      <p>
                        {coin.symbol}
                        <div className="cc-right">
                          <button>{coin.percentageChange.toFixed(2)}%</button>
                        </div>
                        <div>
                          <br />
                          <span>Open : ${coin.openingPrice.toFixed(4)}</span>
                          <br />
                          <span>Price : ${coin.currentPrice.toFixed(4)}</span>
                        </div>
                      </p>
                    </div>
                    {/* <div className="cc-right-lose">
                      <button>{coin.percentageChange.toFixed(2)}%</button>
                    </div> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GainerAndLooser;
