import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMain } from "../../hooks/useMain";
import ScannerCard from "./ScannerCard";

const ScannerDetail = () => {
  const { id } = useParams();
  const { getSingleDashBoard } = useMain();
  const [dashboard, setDashboard] = useState(null);

  const fetchDashboard = async () => {
    if (id) {
      const res = await getSingleDashBoard(id);
      console.log(res);
      setDashboard(res?.data);
    }
  };
  useEffect(() => {

    fetchDashboard();
  }, [id]);


  if (!dashboard) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className="center stockscreen">
        <h1>{dashboard.name?.toUpperCase()}</h1>
        <br />
      </div>

      <div className="scanner-grid">
        {dashboard.scanners?.map((scanner) => (
          <ScannerCard key={scanner._id} id={scanner?._id} scanner={scanner} fetchDashboard={fetchDashboard} />
        ))}
      </div>
    </div>
  );
};

export default ScannerDetail;
