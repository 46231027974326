import React, { useEffect, useRef, useState } from "react";
import { useMain } from "../../hooks/useMain";

const TradingGraph = () => {
  const {chartCall} = useMain()
  const [symbol, setSymbol] = useState("BINANCE:BTCUSDT")
  const container = useRef();

  useEffect(()=>{
    if(chartCall !=""){
      setSymbol(`BINANCE:${chartCall}`)
    }
  },[chartCall])


  useEffect(
    () => {
      const containerDiv = container.current;
      while (containerDiv.firstChild) {
        containerDiv.removeChild(containerDiv.firstChild);
      } 
      const script = document.createElement("script");
      script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
      script.type = "text/javascript";
      script.async = true;
      script.innerHTML = `
        {
          "autosize": true,
          "symbol": "${symbol}",
          "interval": "D",
          "timezone": "Etc/UTC",
          "theme": "light",
          "style": "1",
          "locale": "en",
          "hide_side_toolbar": false,
          "allow_symbol_change": true,
          "calendar": false,
          "support_host": "https://www.tradingview.com"
        }`;
      // container.current.appendChild(script);
      setTimeout(() => {
        if (containerDiv) {
          containerDiv.appendChild(script);
        }
      }, 100);
      return () => {
        while (containerDiv.firstChild) {
          containerDiv.removeChild(containerDiv.firstChild);
        }
      };
    },
    [symbol]
  );

  return (
    <div className="tradingview-widget-container" ref={container} style={{ height: "100%", width: "100%" }}>
      <div className="tradingview-widget-container__widget" style={{ height: "calc(100% - 32px)", width: "100%" }}></div>
      <div className="tradingview-widget-copyright"><a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank"><span className="blue-text">Track all markets on TradingView</span></a></div>
    </div>
  );
  // const container = useRef();

  // useEffect(() => {
  //   const containerDiv = container.current;

  //   // Clean up existing scripts if any
  //   while (containerDiv.firstChild) {
  //     containerDiv.removeChild(containerDiv.firstChild);
  //   }

  //   const script = document.createElement("script");
  //   script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
  //   script.type = "text/javascript";
  //   script.async = true;
  //   script.innerHTML = JSON.stringify({
  //     autosize: true,
  //     symbol,
  //     interval: "D",
  //     timezone: "Etc/UTC",
  //     theme: "light",
  //     style: "1",
  //     locale: "en",
  //     allow_symbol_change: false,
  //     calendar: false,
  //     container_id: "tradingview_advanced_chart"
  //   });
  //   containerDiv.appendChild(script);

  //   // Cleanup function to run when the component unmounts
  //   return () => {
  //     while (containerDiv.firstChild) {
  //       containerDiv.removeChild(containerDiv.firstChild);
  //     }
  //   };
  // }, [symbol]);

  // return (
  //   <div
  //     className="tradingview-widget-container"
  //     ref={container}
      
  //   >
  //     <div
  //       id="tradingview_advanced_chart"
      
  //     ></div>
  //     <div className="tradingview-widget-copyright"  >
  //       <a
  //         href="https://www.tradingview.com/"
  //         rel="noopener nofollow"
  //         target="_blank"
  //       >
  //         <span className="blue-text">Track all markets on TradingView</span>
  //       </a>
  //     </div>
  //   </div>
  // );
};

export default TradingGraph;
