import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { useMain } from "../../hooks/useMain";

const EmailVerificationModal = ({ show, handleClose }) => {
  const { updateEmailOtpsend, updateEmailverify, handleUser, userData } =
    useMain();
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [errors, setErrors] = useState({
    email: "",
    otp: "",
  });

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleResendOtp = async () => {
    try {
      const res = await updateEmailOtpsend({ email });
      if (res) {
        toast.success("OTP resent to your Email.");
      }
    } catch (error) {
      toast.error("Error resending OTP");
    }
  };

  const handleSendOtp = async () => {
    if (!validateEmail(email)) {
      setErrors({ ...errors, email: "Invalid email format." });
      return;
    }
    setErrors({ ...errors, email: "" });
    if (email === userData?.email) {
      return toast.error("Email already exists");
    }
    try {
      const res = await updateEmailOtpsend({ email });
      if (res) {
        toast.success("OTP sent to your email.");
        setIsOtpSent(true);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };

  const handleVerifyOtp = async () => {
    const newErrors = {};
    if (!otp) {
      newErrors.otp = "OTP is required.";
    } else if (otp.length !== 6) {
      newErrors.otp = "OTP must be 6 digits.";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setErrors({ otp: "" });
    try {
      const res = await updateEmailverify({ email, otp });
      console.log(res?.data);
      if (res) {
        handleUser(res?.data);
        toast.success("Email verified successfully!");
        handleClose();
        setIsOtpSent("");
        setEmail("");
        setOtp("");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    setIsOtpSent("");
    setEmail("");
    setOtp("");
  }, [show]);

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Verify Email</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <label>Email</label> &nbsp; &nbsp;
          <input
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={isOtpSent}
          />
          {errors.email && <p className="error">{errors.email}</p>}
        </div>
        {isOtpSent && (
          <div>
            <label>OTP</label> &nbsp; &nbsp;
            <input
              type="text"
              name="otp"
              value={otp}
              onChange={(e) => setOtp(e.target.value.replace(/\D/g, ""))}
            />
            {errors.otp && <p className="error">{errors.otp}</p>}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        {!isOtpSent ? (
          <Button variant="primary" onClick={handleSendOtp}>
            Send OTP
          </Button>
        ) : (
          <>
            <Button variant="primary" onClick={handleVerifyOtp}>
              Verify
            </Button>
            <Button variant="secondary" onClick={handleResendOtp}>
              Resend OTP
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default EmailVerificationModal;
