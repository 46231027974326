import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MainState from './context/MainState';
import { GoogleOAuthProvider } from "@react-oauth/google";



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
	<GoogleOAuthProvider clientId="750009039994-jf9h5smprgi0171ft1h7q1kru3d3lae1.apps.googleusercontent.com" >
      <MainState>
        <ToastContainer autoClose={1000} />
        <App />
      </MainState>
    </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
